export const Menu = [
    {
        title: 'Home',
        to: '/'       
        // dropmenu: 'sub-menu-down',	
        // path:'1' , 
        // content: [
        //     {
        //         subtitle: 'Home 1',
        //         to: '/',					
        //     },                     
        //     {
        //         subtitle: 'Home 2',
        //         to: '/home-2',					
        //     },                     
        //     {
        //         subtitle: 'Home 3',
        //         to: '/home-3',					
        //     },                     
            
        // ],
    },
    {   
        title: 'About us',	
        to:'/about-us'
        // path:'2' , 
        // dropmenu:'sub-menu-down',
        // content : [
        //     {
        //         subtitle:'About Us',
        //         to:'/about-us',
        //     },
        //     {
        //         subtitle:'Team',
        //         to:'/team',
        //     },            
        //     {
        //         subtitle:'Coming Soon',
        //         to:'/coming-soon',
        //     },           
        //     {   
        //         subtitle :'Under Construct',
        //         to : '/under-construct',
        //     },
        //     {   
        //         subtitle :'Error 404',
        //         to : '/error-404',
        //     },
            
        // ],
    },

    {
        title: 'Services',
        to:'/services'
        // dropmenu: 'sub-menu-down',
        // path:'4', 
        // content : [
        //     {
        //         subtitle: 'Services',
        //         to: '/services',
        //     },
        //     {
        //         subtitle: 'Services Details',
        //         to: '/services-details',
        //     },
            
        // ],
    },

    {
        title: 'Portfolio',
        to: '/portfolio',
        // dropmenu: 'sub-menu-down',   
        // path:'3' ,      
        // content: [            
        //     {
        //         subtitle: 'Portfolio',
        //         to: '/portfolio',
        //     },
        //     {
        //         subtitle: 'Portfolio Details',
        //         to: '/portfolio-details',
        //     },
        // ],
    },
    
  
    {
        title: 'Blog',
        to: '/blog-grid',
        path:'4' 
        // dropmenu: 'sub-menu-down',
        // path:'5' , 
        // content:[
        //     {
        //         subtitle:'Blog Grid',
        //         to:'/blog-grid',
        //     },
        //     {
        //         subtitle:'Large Left Sidebar',
        //         to:'/blog-large-left-sidebar',
        //     },
        //     {
        //         subtitle:'List Left Sidebar',
        //         to:'/blog-list-left-sidebar',
        //     },
        //     {
        //         subtitle:'Blog Details',
        //         to:'/blog-details',
        //     },
            
        // ],
    },
    {
        title: 'Contact Us',	
        to: '/contact-us',
        path:'5' 
    },
]