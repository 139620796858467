import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'

const card = [
    { id: 1, delay: 0.2, icon: 'flaticon-blueprint-1' },
    { id: 2, delay: 0.4, icon: 'flaticon-crane' },
    { id: 3, delay: 0.6, icon: 'flaticon-interior-design-1' },
    { id: 4, delay: 0.8, icon: 'flaticon-furniture' },
    { id: 5, delay: 1, icon: 'flaticon-home' },
    { id: 6, delay: 1.2, icon: 'flaticon-support' },

]

const PopularService = () => {
    const [addActive, setAddActive] = useState(1);
    const firstItem = card[0];
    const secondItem = card[1];
    const thirdItem = card[2];

    return (
        <>
            <div className="container">
                <div className="section-head style-1 text-center">
                    <h6 className="sub-title text-primary">POPULAR SERVICES</h6>
                    <h2 className="title">Our Featured Services</h2>
                </div>
                <div className="row">

                    <motion.div key={firstItem.id} className="col-lg-4 col-sm-6 aos-item" 
                        initial={{ opacity: 0, y: "100%" }}
                        whileInView={{ opacity: 1, y: "0%" }}
                        transition={{ duration: 0.8, delay: firstItem.delay }}
                    >
                        <div className={`icon-bx-wraper style-3  m-b30 ${addActive === firstItem.id ? 'active' : ''}`}
                            onMouseEnter={() => setAddActive(firstItem.id)}
                        >
                            <div className="icon-xl m-b30">
                                <Link className="icon-cell"><i className={firstItem.icon}></i></Link>
                            </div>
                            <div className="icon-content">
                                <h4 className="title m-b10"><Link to="/service-details">Property development</Link></h4>
                                <p className="m-b30">We are dedicated to creating high-quality, eco-friendly residential and commercial spaces. Our sustainable designs and materials ensure a superior living and working experience that benefits both people and the planet.</p>
                                <Link to="/Service-Detail1" className="btn btn-primary btn-rounded btn-sm hover-icon">
                                    <span>Read More</span>
                                    <i className="fas fa-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </motion.div>

                    <motion.div key={secondItem.id} className="col-lg-4 col-sm-6 aos-item" 
                        initial={{ opacity: 0, y: "100%" }}
                        whileInView={{ opacity: 1, y: "0%" }}
                        transition={{ duration: 0.8, delay: secondItem.delay }}
                    >
                        <div className={`icon-bx-wraper style-3  m-b30 ${addActive === secondItem.id ? 'active' : ''}`}
                            onMouseEnter={() => setAddActive(secondItem.id)}
                        >
                            <div className="icon-xl m-b30">
                                <Link className="icon-cell"><i className={secondItem.icon}></i></Link>
                            </div>
                            <div className="icon-content">
                                <h4 className="title m-b10"><Link to="/service-details">Sustainable Residential Construction</Link></h4>
                                <p className="m-b30">Our focus is on creating energy-efficient, comfortable homes. Each project incorporates green building practices to provide a healthy living environment while reducing our ecological footprint.</p>
                                <Link to="/Service-Detail2" className="btn btn-primary btn-rounded btn-sm hover-icon">
                                    <span>Read More</span>
                                    <i className="fas fa-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </motion.div>

                    
                    <motion.div key={thirdItem.id} className="col-lg-4 col-sm-6 aos-item" 
                        initial={{ opacity: 0, y: "100%" }}
                        whileInView={{ opacity: 1, y: "0%" }}
                        transition={{ duration: 0.8, delay: thirdItem.delay }}
                    >
                        <div className={`icon-bx-wraper style-3  m-b30 ${addActive === thirdItem.id ? 'active' : ''}`}
                            onMouseEnter={() => setAddActive(thirdItem.id)}
                        >
                            <div className="icon-xl m-b30">
                                <Link className="icon-cell"><i className={thirdItem.icon}></i></Link>
                            </div>
                            <div className="icon-content">
                                <h4 className="title m-b10"><Link to="/service-details">Eco-friendly Commercial Construction</Link></h4>
                                <p className="m-b30">We specialise in innovative commercial construction that prioritises sustainability. Our buildings are designed to be functional, aesthetically pleasing, and environmentally responsible. </p>
                                <Link to="/Service-Detail3" className="btn btn-primary btn-rounded btn-sm hover-icon">
                                    <span>Read More</span>
                                    <i className="fas fa-arrow-right"></i>
                                </Link>
                            </div>
                        </div>
                    </motion.div>
                </div>
            </div>
        </>
    );
}



export default PopularService;
