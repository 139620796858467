import React from 'react';
import CommanBanner from '../elements/CommanBanner';
import { IMAGES } from '../constants/theme';
import Working from '../components/Working';
import Projects from '../components/Projects';
import Testimonial1 from '../components/Testimonial1';
import Testimonial2 from '../components/Testimonial2';
import Faq from '../components/Faq';
import {motion} from 'framer-motion'
import ComingSoon from './ComingSoon';
import Error404 from './Error404'

const workingProcess = [
    { num: "01", heading: "Receive Project" },
    { num: "02", heading: "Planning Work" },
    { num: "03", heading: "Creative Design" },
    { num: "04", heading: "Start Building" },
]

const AboutUs = () => {
    const firstItem = workingProcess[0];
    const secondItem = workingProcess[1];
    const thirdItem = workingProcess[2];
    const forthItem = workingProcess[3];

    return (
        <>
            <div className="page-content bg-white">
                <CommanBanner mainTitle="About Us" parentTitle="Home" pageName="About Us" bgImage={IMAGES.bannerbg3} />
                <section className="section-full content-inner about-bx2" style={{ backgroundImage: `url(${IMAGES.background2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <div className="container">
                        <ComingSoon/>
                    </div>
                </section>

                {/* <section className="section-full content-inner about-bx2" style={{ backgroundImage: `url(${IMAGES.background2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <div className="container">
                        <Error404/>
                    </div>
                </section> */}


                <section className="content-inner-2">
                    <div className="container">
                        <div className="section-head style-1 text-center">
                            <h6 className="sub-title text-primary">WORK PROCESS</h6>
                            <h2 className="title">How Our Work Process</h2>
                        </div>
                     <div className="row">
                    {/* First Item */}
                    <motion.div className="col-lg-3 col-sm-6" key={firstItem.num}
                        initial={{ opacity: 0, y: '100%' }}
                        whileInView={{ opacity: 1, y: "0" }}
                        transition={{ duration: 0.8}}
                    >
                        <div className="work-process shadow text-center m-b30">
                            <div className="number">{firstItem.num}</div>
                            <h4 className="title m-b15">Project Consultation: Discuss sustainable requirements, budget, and expectations.</h4>
                            <p className="m-b0">Clients liaise with the architectural firm to discuss requirements, budget, and expectations.</p>
                        </div>
                    </motion.div>

                    {/* Second Item */}
                    <motion.div className="col-lg-3 col-sm-6" key={secondItem.num}
                        initial={{ opacity: 0, y: '100%' }}
                        whileInView={{ opacity: 1, y: "0" }}
                        transition={{ duration: 0.8}}
                    >
                        <div className="work-process shadow text-center m-b30">
                            <div className="number">{secondItem.num}</div>
                            <h4 className="title m-b15">Project Evaluation and Selection: Evaluate potential projects with a focus on sustainable and green building opportunities.</h4>
                            <p className="m-b0">Potential construction projects are evaluated, including location, development potential, expected returns, etc.</p>
                        </div>
                    </motion.div>

                       {/* Third Item */}
                    <motion.div className="col-lg-3 col-sm-6" key={thirdItem.num}
                        initial={{ opacity: 0, y: '100%' }}
                        whileInView={{ opacity: 1, y: "0" }}
                        transition={{ duration: 0.8}}
                    >
                        <div className="work-process shadow text-center m-b30">
                            <div className="number">{thirdItem.num}</div>
                            <h4 className="title m-b15">Financial Planning and Fund Raising: Plan and secure funds for eco-friendly and energy-efficient projects.</h4>
                            <p className="m-b0">Determine the financial needs of the investment project.</p>
                        </div>
                    </motion.div>

                       {/* Forth Item */}

                       <motion.div className="col-lg-3 col-sm-6" key={forthItem.num}
                        initial={{ opacity: 0, y: '100%' }}
                        whileInView={{ opacity: 1, y: "0" }}
                        transition={{ duration: 0.8}}
                    >
                        <div className="work-process shadow text-center m-b30">
                            <div className="number">{forthItem.num}</div>
                            <h4 className="title m-b15">Return on Investment and Analysis: Analyse project returns, ensuring they align with our green building goals and sustainability metrics.</h4>
                            <p className="m-b0">Adjust future investment strategies based on project results.</p>
                        </div>
                    </motion.div>
                </div>
                </div>
                </section>
                {/* <section className="content-inner-2">
                    <Projects />
                </section> */}
                <section className="content-inner-2" style={{ backgroundImage: `url(${IMAGES.bg2})`, backgroundPosition: 'right bottom', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    {/* <Testimonial1 /> */}
                </section>
                <section className="content-inner-2">
                    {/* <Testimonial2 /> */}
                </section>
                <section className="section-full content-inner overflow-hidden" style={{ backgroundImage: `url(${IMAGES.bg1})`, backgroundPosition: 'left top', backgroundSize: '100%', backgroundRepeat: 'no-repeat' }}>
                    <Faq />
                </section>
            </div>
            
        </>
    )
}
export default AboutUs;