import React from 'react'
import CommanBanner from '../elements/CommanBanner'
import { IMAGES } from '../constants/theme'
import LargeSidebar from '../components/LargeSidebar'
import { Link } from 'react-router-dom'



const ServiceDetail2 = () => {
    const centerStyle = {
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center',
        minHeight: '100vh', 
    };
    return (
        <>
         <div style={centerStyle}>
            <div className="page-content bg-white">
                <CommanBanner mainTitle="Residential Construction" parentTitle="Home" pageName="About Us" bgImage={IMAGES.bnr2} />
                <div className="content-inner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2">
                                <div className="dz-card blog-single style-1">
                                    <div className="dz-media">
                                        <img src={IMAGES.blogGridPic2} alt="" />
                                    </div>
                                    <div className="dz-info">
                                  
                                        <h2 className="dz-title">Residential Construction</h2>
                                        <div className="dz-post-text">

                                            <p>Our commitment to our clients is to deliver high quality, high value residential projects. We are committed to using advanced building techniques and quality materials to ensure that each project not only meets the demands of modern living, but also meets industry-leading standards in sustainability and environmental protection.</p>

                                            <blockquote className="block-quote style-1">
                                                <p>Welcome to Cyberidge, a leading construction company specialising in innovative and sustainable building concepts. Our core philosophy is to create economic value while positively impacting society and the environment. We use sustainable materials, energy-efficient designs, and eco-friendly practices to ensure our projects support a greener future. </p>
                            
                                            </blockquote>
                                            <div className="alignleft w-50">
                                                <img alt="" src={IMAGES.blogGridPic2} className="w-100" />
                                            </div>
                                            <p>Through professional risk management and transparent operational processes, we aim to We aim to create stable and reliable investment returns for our clients, and at the same time, we are committed to building harmonious communities and providing a better and more livable living environment for our residents. Every investment is not only an increase in wealth, but also the pursuit of a high-quality lifestyle.</p>
                                            <h4>Our Advantages</h4>
                                            <p>Expertise and experience: </p>
                                            <p> We have a team of professionals with in-depth understanding of the residential market, construction industry and investment strategies to effectively identify and evaluate investment opportunities.</p>
                                            <p>Stable investment returns:</p>
                                            <p>We will viewed as a stable investment channel, offering long-term capital appreciation potential and stable rental income.</p>
                                            <p>Value-added services:</p>
                                            <p>We will provides a full range of services including market research, property management, maintenance and leasing services to enhance the overall value of the investment.</p>
                                            <p>Market Adaptability:</p>
                                            <p>We are only responsible for the purchase and sale of properties, but also provide property management services including repairs, leasing and day-to-day management, thus reducing the burden on the investor.</p>
                                            <p>Social Impact:</p>
                                            <p>Promote community development and economic growth through investment in residential building projects, enhancing the company's social responsibility and brand image.</p>

                                        </div>
                                
                                    </div>
                                </div>
                      
                          
                      


                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </>)
}

export default ServiceDetail2