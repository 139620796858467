import React from 'react'
import CommanBanner from '../elements/CommanBanner'
import { IMAGES } from '../constants/theme'
import LargeSidebar from '../components/LargeSidebar'
import { Link } from 'react-router-dom'



const ServiceDetail1 = () => {
    const centerStyle = {
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center',
        minHeight: '100vh', 
    };
    return (
        <>
         <div style={centerStyle}>
            <div className="page-content bg-white">
                <CommanBanner mainTitle="Property development" parentTitle="Home" pageName="About Us" bgImage={IMAGES.bnr2} />
                <div className="content-inner">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-8 col-lg-8 offset-xl-2 offset-lg-2">
                                <div className="dz-card blog-single style-1">
                                    <div className="dz-media">
                                        <img src={IMAGES.blogGridPic2} alt="" />
                                    </div>
                                    <div className="dz-info">
                                  
                                        <h2 className="dz-title">Property development</h2>
                                        <div className="dz-post-text">

                                            <p>We are committed to providing our clients with high-return and reliable investment opportunities. We meticulously select premium real estate projects, 
                                                encompassing residential, commercial, and industrial properties, aimed at delivering long-term capital appreciation and stable rental income for investors.
                                                 Our team is composed of seasoned industry experts who possess a deep understanding of market trends and investment strategies. They are capable of 
                                                 offering clients data-driven insights and personalized investment advice.</p>

                                            <blockquote className="block-quote style-1">
                                                <p>Welcome to Cyberidge, a leading construction company specialising in innovative and sustainable building concepts. Our core philosophy is to create economic value while positively impacting society and the environment. We use sustainable materials, energy-efficient designs, and eco-friendly practices to ensure our projects support a greener future. </p>
                            
                                            </blockquote>
                                            <div className="alignleft w-50">
                                                <img alt="" src={IMAGES.blogGridPic2} className="w-100" />
                                            </div>
                                            <p>Our clientele includes individual investors, family trusts, and institutional investors. Whether they are novice investors or experienced asset managers, we are dedicated to establishing a transparent and mutually trusting relationship, ensuring that each client can find the most suitable real estate investment plan according to their investment goals and risk preferences. </p>
                                            <h4>Our Advantages</h4>
                                            <p>Expertise and market analysis:</p>
                                            <p>We have a team of professionals and we have an in-depth understanding of the property market and the ability to analyse it. We are able to identify market trends and provide investors with investment advice based on data and research.</p>
                                            <p>Project Diversity: </p>
                                            <p>We will offer a wide range of different types of property projects, including residential, commercial, industrial and retail properties, to provide investors with a wider choice.</p>
                                            <p>Risk Diversification:</p>
                                            <p>By investing in multiple projects or properties, we will help our investors diversify their risks. This is a more effective way of managing the risk of potential market fluctuations than investing in a single property alone.</p>
                                            <p>Asset Management and Maintenance:</p>
                                            <p>We are only responsible for the purchase and sale of properties, but also provide property management services including repairs, leasing and day-to-day management, thus reducing the burden on the investor.</p>
                                            <p>AMarket Adaptability:</p>
                                            <p>We able to adjust our strategies in response to changes in the market, for example, by purchasing properties during market downturns and selling them at market peaks in order to maximise profits.</p>

                                        </div>
                                
                                    </div>
                                </div>
                      
                          
                      


                            </div>
                          
                        </div>
                    </div>
                </div>
            </div>
            </div>

        </>)
}

export default ServiceDetail1